
import { defineComponent } from 'vue'

export default defineComponent({
  name:'RCheckbox',
  props:{
    modelValue:{
      type:String || Boolean,
      default:false
    },
    index: {
      type:String || Boolean,
      default:true
    },
    title: {
      type:String,
      default:''
    }
  },
  setup(props,{emit}) {
    const handclick = () => {
      emit("change",props.index);
      emit("update:modelValue",props.index)
    }
    return {handclick}
  },
})
