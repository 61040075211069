
import { defineComponent,ref,computed } from "vue";

function isKorean(text: string): boolean {
  const reg = /([(\uAC00-\uD7AF)|(\u3130-\u318F)])+/gi
  return reg.test(text)
}
export default defineComponent({
  name: "RInput",
  emits:["focus","blur","update:modelValue","input","change","compositionstart","compositionupdate","compositionend"],
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
    reg: {
      type:String,
      default:''
    },
    errMsg: {
      type:String,
      default:''
    },
    error: {
      type:Boolean,
      default:false
    },
    readonly: {
      type:Boolean,
      default:false
    },
    value: {
      type: [String, Number],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    rows:{
      type:Number,
      default:5
    },
    autocomplete: {
      type: String,
      default: "off",
      validator: (val: string) => ["on", "off"].includes(val),
    },
    type: {
      type: String,
      default: "text",
      validator:(val:string) => ["text","password","textarea"].includes(val)
    },
  },
  setup(props,{emit}) {
    const handleinput = (event:Event) => {
      const { value } = event.target as HTMLInputElement;
      emit("update:modelValue", value)
      emit('input', value)
      
    }
    const handlechange = (event:Event) => {
      const { value } = event.target as HTMLInputElement;
      if(props.reg) {
        // check
        const reg = new RegExp(props.reg)
        if(!reg.test(value)) {
          err.value = true;
          return;
        }
        err.value = false;
      }
      emit('change', value)
    }
    const err = ref(false);
    const errs = computed(() => {
      return props.error || err.value ? props.errMsg:'';
    })
    const check = () => {
      return err.value;
    }
    const handleblur = (event:Event) => {
      const { value } = event.target as HTMLInputElement;
      setTimeout(()=>{
         emit("blur");
      },100)
    }
    const handlefocus = () => {
      emit("focus")
    }
    return {handlefocus,handleblur,check,errs,handlechange,handleinput}
  },
});
