import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fd97ab68"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "r-slide" }
const _hoisted_2 = { class: "sl1 slm" }
const _hoisted_3 = {
  class: "mt20 m50 text black-color",
  id: "font-size"
}
const _hoisted_4 = { class: "m50 fl1" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  class: "h5",
  id: "font-size"
}
const _hoisted_7 = {
  class: "h5",
  id: "font-size"
}
const _hoisted_8 = { class: "sl1 bkg" }
const _hoisted_9 = { class: "box" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "sl1 slm" }
const _hoisted_12 = {
  class: "m50 mt20 text black-color",
  id: "font-size"
}
const _hoisted_13 = { class: "m50 fl1" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "h5" }
const _hoisted_16 = { class: "h5" }
const _hoisted_17 = { class: "sl1 bkg" }
const _hoisted_18 = { class: "box" }
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "sl1 slm" }
const _hoisted_21 = {
  class: "m50 mt20 text black-color",
  id: "font-size"
}
const _hoisted_22 = { class: "m50 fl1" }
const _hoisted_23 = ["src"]
const _hoisted_24 = { class: "h5" }
const _hoisted_25 = { class: "h5" }
const _hoisted_26 = { class: "sl1 bkg" }
const _hoisted_27 = { class: "box" }
const _hoisted_28 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_r_icon = _resolveComponent("r-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onTouchstart: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onstart && _ctx.onstart(...args))),
      onTouchend: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onend && _ctx.onend(...args))),
      onTouchmove: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onmove && _ctx.onmove(...args))),
      class: "list"
    }, [
      _createElementVNode("div", {
        style: _normalizeStyle({ left: `${_ctx.pos}%` }),
        class: _normalizeClass([_ctx.tr ? 'slide-tr' : '', "slide-item panel"])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_r_icon, {
            class: "m50",
            icon: "dou",
            size: _ctx.size
          }, null, 8, ["size"]),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.currect.info), 1),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.currect.head)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  width: "82",
                  height: "82",
                  src: _ctx.currect.head
                }, null, 8, _hoisted_5))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.currect.name), 1),
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.currect.title), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("img", {
              src: _ctx.currect.icon
            }, null, 8, _hoisted_10)
          ])
        ])
      ], 6),
      _createElementVNode("div", {
        style: _normalizeStyle({ left: `${_ctx.pos1}%` }),
        class: _normalizeClass([_ctx.tr ? 'slide-tr' : '', "slide-item panel"])
      }, [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_r_icon, {
            class: "m50",
            icon: "dou",
            size: _ctx.size
          }, null, 8, ["size"]),
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.items[_ctx.nxt].info), 1),
          _createElementVNode("div", _hoisted_13, [
            (_ctx.items[_ctx.nxt].head)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  width: "82",
                  height: "82",
                  src: _ctx.items[_ctx.nxt].head
                }, null, 8, _hoisted_14))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.items[_ctx.nxt].name), 1),
              _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.items[_ctx.nxt].title), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("img", {
              src: _ctx.items[_ctx.nxt].icon
            }, null, 8, _hoisted_19)
          ])
        ])
      ], 6),
      _createElementVNode("div", {
        style: _normalizeStyle({ left: `${_ctx.pos2}%` }),
        class: _normalizeClass([_ctx.tr ? 'slide-tr' : '', "slide-item panel"])
      }, [
        _createElementVNode("div", _hoisted_20, [
          _createVNode(_component_r_icon, {
            class: "m50",
            icon: "dou",
            size: _ctx.size
          }, null, 8, ["size"]),
          _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.items[_ctx.pre].info), 1),
          _createElementVNode("div", _hoisted_22, [
            (_ctx.items[_ctx.pre].head)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  width: "82",
                  height: "82",
                  src: _ctx.items[_ctx.pre].head
                }, null, 8, _hoisted_23))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.items[_ctx.pre].name), 1),
              _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.items[_ctx.pre].title), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("img", {
              src: _ctx.items[_ctx.pre].icon
            }, null, 8, _hoisted_28)
          ])
        ])
      ], 6)
    ], 32),
    _createVNode(_component_r_icon, {
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.next(-1))),
      size: 40,
      class: "hide ic il",
      icon: "navl"
    }),
    _createVNode(_component_r_icon, {
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.next())),
      size: 40,
      class: "hide ic ir",
      icon: "navr"
    })
  ]))
}