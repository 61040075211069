
import { defineComponent } from 'vue'

export default defineComponent({
  name:'RButton',
  emits:['click'],
  props:{
    label:{
      type:String,
      default:""
    },
    type: {
      type:String,
      default:""
    }
  },
  setup(props,{emit}) {
    const handleclick = (evt: MouseEvent) => {
      emit('click',evt)
    }
    return {handleclick}
  }
})
