
import { defineComponent, nextTick, ref } from "vue";
import Poper from "../../poper/src/index.vue";

export default defineComponent({
  name: "RDropdown",
  components: { Poper },
  props: {
    title: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ["command", "on-mouseleave"],
  setup(props, { emit }) {
    const showmenu = ref(false);
    const drop = ref(null);
    const left = ref(0);
    const top = ref(0);
    const handleclick = (item: any) => {
      emit("command", item);
      showmenu.value = false;
      if(isopen.value) {
        closem();
      }
    };
    let timer: any = 0;
    const handleenter = () => {
      showmenu.value = true;
      const dom: any = drop.value;
      const rect: any = dom.getBoundingClientRect();
      const y = window.pageYOffset;
      const x = window.pageXOffset;
      left.value = rect.left + x;
      top.value = rect.bottom + 3 + y;
      if (timer) {
        clearTimeout(timer);
      }
      timer = 0;
    };
    const handleleave = () => {
      if(isopen.value) return;
      timer = setTimeout(() => {
        emit("on-mouseleave");
        showmenu.value = false;
      }, 100);
    };
    const closem = () => {
      showmenu.value = false;
      isopen.value = false;
      window.removeEventListener('click',closem);
    }
    const isopen = ref(false);
    const handleopen = () => {
      console.log('ja...')
      if(isopen.value) return;
      isopen.value= true;
      showmenu.value = true;
      setTimeout(()=>{
        window.addEventListener('click',closem);
      },100)
      handleenter();
    }
    return { handleopen,left, top, showmenu, handleclick, drop, handleenter, handleleave };
  },
});
