
import { defineComponent, ref } from "vue";
import Drop from "../../dropdown";

export default defineComponent({
  props: ["data", "mode", "theme"],
  components: { Drop },
  setup(props, { emit }) {
    const showsub = ref(false);
    const handleclick = (key: string) => {
      if (key) {
        emit("command", key);
        return;
      }
      if (props.data.items) {
        if (!showsub.value) {
          emit("subopen");
        }
        showsub.value = !showsub.value;
      } else {
        emit("command", props.data.key || props.data);
      }
    };
    const closemenu = () => {
      showsub.value = false;
    };
    return { closemenu, showsub, handleclick };
  },
});
