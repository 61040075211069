
import { defineComponent, reactive, ref, nextTick, onMounted, onBeforeUnmount } from "vue";
const head1 = require("../../assets/imgs/head1.png");
const head2 = require("../../assets/imgs/head2.png");
const head3 = require("../../assets/imgs/head3.png");
const head4 = require("../../assets/imgs/head4.png");
const head5 = require("../../assets/imgs/head5.png");
const ipay = require("../../assets/imgs/ipay.png");
const nium = require("../../assets/imgs/nium.png");
const faire = require("../../assets/imgs/faire.png");
const rr7 = require("../../assets/imgs/treviso.png");
const ecs = require("../../assets/imgs/ecs.png");

export default defineComponent({
  setup(props, { emit }) {
    const items = [
      {
        info: "Our partnership with Roxe reflects our continued innovation in providing the most reliable, fastest, and cost-effective payments and remittance services to our customers across the world.",
        name: "Tan Ching Khoon",
        title: "Chairman of IPAY Group",
        head: head1,
        icon: ipay,
      },
      {
        info: "We are delighted to work with like-minded partners such as Roxe, to serve their mission of empowering banks, payment service providers and other connected financial institutions to provide real-time, low-cost payments, and payment acceptance to their customers across the world.",
        name: "Michael Bermingham",
        title: "Chief Business Officer, Co-Founder | Nium",
        head: head2,
        icon: nium,
      },
      {
        info: "We chose the Roxe network because it helps us advance our mission to provide the best currency exchange rates for banks and businesses to send, receive, and manage cross-border payments faster, easier, and at significant savings.",
        name: "Raj Kattelu",
        title: "CEO, Founder | Fairexpay",
        head: "",
        icon: faire,
      },
      {
        info: "Roxe's services are integrated with Treviso’s ESB (Enterprise Service Bus). This integration is Online and Real-time through blockchain technology, enabling real-time settlement of exchange transactions through Roxe's partners worldwide.",
        name: "Rodrigo Ximendes",
        title: "CTO | Treviso",
        head: head3,
        icon: rr7,
      },
      {
        info: "Treviso customers will be able to use the Roxe channels to complete international payments online, simplifying the process and improving the customer experience.",
        name: "Mauro Neto",
        title: "COO | Treviso",
        head: head4,
        icon: rr7,
      },
      {
        info: "Treviso invests in technology and strategic partnerships to provide better, more efficient, and secure foreign exchange solutions for our customers. Our partnership with Roxe will help us expand our operations in our domestic market as one of the Roxe payment nodes.",
        name: "Cristiano Abdalla",
        title: "CEO | Treviso",
        head: head5,
        icon: rr7,
      },
      {
        info: "We chose to engage the Roxe network in our payments platform since it provides tremendous value to our bank customers for both domestic and cross-border payments.",
        name: "Jacob Aruldhas",
        title: "CEO | ECS Fin",
        head: "",
        icon: ecs,
      },
    ];
    const currect = reactive({
      info: "Our partnership with Roxe reflects our continued innovation in providing the most reliable, fastest, and cost-effective payments and remittance services to our customers across the world.",
      name: "Tan Ching Khoon",
      title: "Chairman of IPAY Group",
      head: head1,
      icon: ipay,
    });
    const index = ref(0);
    const pos = ref(0);
    const pos1 = ref(100);
    const pos2 = ref(-100);
    let loading = false;
    const pre = ref(6);
    const nxt = ref(1);
    const next = (dir = 1) => {
      if (loading) return;
      loading = true;
      if (dir == 1) {
        tr.value = true;
        pos1.value = 0;
        pos.value = -100;
      } else {
        tr.value = true;
        pos2.value = 0;
        pos.value = 100;
      }
      setTimeout(() => {
        tr.value = false;
        pos.value = 0;
        pos1.value = 100;
        pos2.value = -100;
        loading = false;
        index.value = index.value + dir;
        emit("change", index.value);
        if (index.value < 0) {
          index.value = items.length - 1;
        }
        index.value = index.value % items.length;
        pre.value = index.value - 1;
        if (pre.value < 0) pre.value = items.length - 1;
        nxt.value = index.value + 1;
        if (nxt.value >= items.length) {
          nxt.value = 0;
        }
        currect.info = items[index.value].info;
        currect.name = items[index.value].name;
        currect.title = items[index.value].title;
        currect.head = items[index.value].head;
        currect.icon = items[index.value].icon;
      }, 500);
    };
    const tr = ref(true);
    const size = ref(59);
    if (document.documentElement.clientWidth < 500) {
      size.value = 32;
    }
    const timer = setInterval(() => {
      next(1);
    }, 1100000);
    onBeforeUnmount(() => {
      clearInterval(timer);
    });
    const txx = ref(0);
    const exx = ref(0);
    const onstart = (ev: TouchEvent) => {
      txx.value = ev.touches[0].clientX;
      console.log(`start:${txx.value}`);
    };
    const onend = (ev: any) => {
      if (exx.value > txx.value + 100) {
        next(-1);
      } else if (exx.value < txx.value - 100) {
        next(1);
      }
    };
    const onmove = (ev: any) => {
      exx.value = ev.touches[0].clientX;
    };
    const gotoside = (idx: number) => {
      tr.value = true;
      pos1.value = 0;
      pos.value = -100;
      nxt.value = idx;
      setTimeout(() => {
        tr.value = false;
        pos.value = 0;
        pos1.value = 100;
        pos2.value = -100;
        loading = false;
        index.value = idx;
        currect.info = items[index.value].info;
        currect.name = items[index.value].name;
        currect.title = items[index.value].title;
        currect.head = items[index.value].head;
        currect.icon = items[index.value].icon;
        pre.value = index.value - 1;
        if (pre.value < 0) pre.value = items.length - 1;
        nxt.value = index.value + 1;
        if (nxt.value >= items.length) {
          nxt.value = 0;
        }
      }, 500);
    };
    return {
      gotoside,
      onmove,
      onend,
      onstart,
      pre,
      nxt,
      size,
      tr,
      pos,
      pos1,
      pos2,
      next,
      currect,
      items,
    };
  },
});
