interface BinInfo {
  value: any;
}
export default {
  mounted(el: HTMLElement, binding:BinInfo){
    //app ping
    if(document.documentElement.clientWidth <= 80000) {
      return;
    }
    let loaded =false;
    let ldd = false;
    el.style.opacity ='0';
    el.style.transform = `translate(0px,80px)`
    const out = binding.value || 100;
    let btn = 0;
    const checkin = () => {
      if(loaded) {
        loaded = false;
        ldd = true;
        const rect = el.getBoundingClientRect();
        btn = rect.top + rect.height/2 + (window.scrollY ||  window.pageYOffset);
      }
      if(!ldd) return;
      const topy = window.scrollY ||  window.pageYOffset;
      if(btn > topy && btn < topy + document.documentElement.clientHeight) {
        return true;
      }
      return false;
    }
    const onscroll =  () => {
      if(checkin()) {
        window.removeEventListener('scroll',onscroll);
        setTimeout(() => {
          el.className = el.className + ' enter'
          el.style.opacity ='1';
          el.style.transform = 'translate(0px, 0px)'
        }, out);
      }
    }
    // 监听滚动事件
    window.addEventListener('scroll',onscroll);
    setTimeout(()=>{
      loaded = true;
      onscroll()
    },100);
  }
}