
import { defineComponent, computed } from "vue";
import Model from "./index.vue";
import { useStore } from "vuex";
import { RootState } from "@/store";

export default defineComponent({
  components: { Model },
  setup(prop, { emit }) {
    const store = useStore<RootState>();
    const data = computed(()=>{
      return store.state.ui.pop;
    });
    const handleclose = () => {
      store.commit("ui/closePop");
    };
    return { handleclose,data };
  },
});
