
import { defineComponent,onBeforeUnmount } from 'vue'

export default defineComponent({
  name:'RPoper',
  props:{
    hide:{
      type:Boolean,
      default:false
    },
    left: {
      type:Number,
      default:0
    },
    top: {
      type:Number,
      default:0
    }
  },
  setup() {
    onBeforeUnmount(()=>{
      console.log('destroy .....')
    });
  }
})
