import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Login from "../views/login/index.vue";
import Checkout from "../views/checkout/index.vue";
import Home from "../views/Home.vue";
import store from "../store";
import { userinfo } from "@/helper/api";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/consumer",
    name: "Consumers",
    component: () => import(/* webpackChunkName: "consumer" */ "../views/app/consumer.vue"),
  },
  {
    path: "/developers",
    name: "Developers",
    component: () => import(/* webpackChunkName: "consumer" */ "../views/doc.vue"),
  },
  {
    path: "/merchant",
    name: "Roxe Pay",
    component: () => import(/* webpackChunkName: "merchant" */ "../views/app/merchants.vue"),
  },
  {
    path: "/squadbd",
    name: "Social BD",
    component: () => import(/* webpackChunkName: "squadbd" */ "../views/app/squadbd.vue"),
  },
  {
    path: "/risn",
    name: "RISN",
    component: () => import(/* webpackChunkName: "risn" */ "../views/risn.vue"),
  },
  {
    path: "/case-studies-ecsfin",
    name: "Case Study",
    component: () => import(/* webpackChunkName: "Studies" */ "../views/case.vue"),
  },
  {
    path: "/about-us",
    name: "About Us",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About/About.vue"),
  },
  {
    path: "/press",
    name: "Press Releases",
    component: () => import(/* webpackChunkName: "press" */ "../views/About/press.vue"),
  },
  {
    path: "/blog",
    name: "Blog",
    component: () => import(/* webpackChunkName: "blog" */ "../views/blob/index.vue"),
  },
  {
    path: "/career",
    name: "Career",
    component: () => import(/* webpackChunkName: "career" */ "../views/About/career.vue"),
  },
  {
    path: "/RISN-FAQs",
    name: "RISN-FAQs",
    component: () => import(/* webpackChunkName: "career" */ "../views/About/RISN-FAQs.vue"),
  },
  {
    path: "/QR-Payment-FAQs",
    name: "QR-Payment-FAQs",
    component: () => import(/* webpackChunkName: "career" */ "../views/About/QR-Payment-FAQs.vue"),
  },
  {
    path: "/Squad-deal-FAQs",
    name: "Squad-deal-FAQs",
    component: () => import(/* webpackChunkName: "career" */ "../views/About/Squad-deal-FAQs.vue"),
  },
  {
    path: "/Egiftcard-FAQs-Questions",
    name: "Egiftcard-FAQs-Questions",
    component: () =>
      import(/* webpackChunkName: "career" */ "../views/About/Egiftcard-FAQs-Questions.vue"),
  },
  {
    path: "/career/list",
    name: "Career Detail",
    component: () => import(/* webpackChunkName: "career" */ "../views/About/job.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import(/* webpackChunkName: "contact" */ "../views/About/contact.vue"),
  },
  {
    path: "/legal-and-privacy",
    name: "Legal & Privacy",
    component: () => import(/* webpackChunkName: "terms" */ "../views/legal/legal-and-privacy.vue"),
  },
  {
    path: "/app-user-agreement-us",
    name: "Roxe App User Agreement US",
    component: () =>
      import(
        /* webpackChunkName: "app-user-agreement-us" */ "../views/legal/app-user-agreement-us.vue"
      ),
  },
  {
    path: "/prohibited-uses",
    name: "Prohibited Uses",
    component: () =>
      import(/* webpackChunkName: "app-user-agreement-us" */ "../views/legal/prohibited-uses.vue"),
  },
  {
    path: "/regulators",
    name: "Regulators",
    component: () => import(/* webpackChunkName: "regulators" */ "../views/legal/regulators.vue"),
  },
  {
    path: "/beta-tester-ua",
    name: "Beta Tester User Agreement",
    component: () =>
      import(/* webpackChunkName: "beta-tester-ua" */ "../views/legal/beta-tester-ua.vue"),
  },
  {
    path: "/api-agreement",
    name: "API Agreement",
    component: () =>
      import(/* webpackChunkName: "api-agreement" */ "../views/legal/api-agreement.vue"),
  },
  {
    path: "/cookie-policy",
    name: "Cookie Policy",
    component: () =>
      import(/* webpackChunkName: "cookie-policy" */ "../views/legal/cookie-policy.vue"),
  },
  {
    path: "/glba-privacy-notice",
    name: "GLBA Privacy Notice",
    component: () =>
      import(
        /* webpackChunkName: "glba-privacy-notice" */ "../views/legal/glba-privacy-notice.vue"
      ),
  },
  {
    path: "/california-privacy-notice",
    name: "California Privacy Notice",
    component: () =>
      import(
        /* webpackChunkName: "california-privacy-notice" */ "../views/legal/california-privacy-notice.vue"
      ),
  },
  {
    path: "/general-privacy",
    name: "Privacy Policy",
    component: () =>
      import(/* webpackChunkName: "general-privacy" */ "../views/legal/general-privacy.vue"),
  },
  {
    path: "/esifn-disclosure",
    name: "E-Sign Disclosure and Consent",
    component: () =>
      import(/* webpackChunkName: "esifn-disclosure" */ "../views/legal/esifn-disclosure.vue"),
  },
  {
    path: "/roxe-merchant",
    name: "Roxe Merchant Agreement",
    component: () =>
      import(/* webpackChunkName: "roxe-merchant" */ "../views/legal/roxe-merchant.vue"),
  },
  {
    path: "/roxe-rules",
    name: "Roxe RISN Rules",
    component: () => import(/* webpackChunkName: "roxe-rules" */ "../views/legal/roxe-rules.vue"),
  },
  {
    path: "/general-terms",
    name: "General Terms and Conditions",
    component: () =>
      import(/* webpackChunkName: "general-terms" */ "../views/legal/general-terms.vue"),
  },
  {
    path: "/squad-deals-term",
    name: "quad Deals Terms of Use",
    component: () =>
      import(/* webpackChunkName: "squad-deals-term" */ "../views/legal/squad-deals-term.vue"),
  },
  {
    path: "/legal/terms",
    name: "Terms",
    component: () => import(/* webpackChunkName: "terms" */ "../views/legal/terms.vue"),
  },
  {
    path: "/legal/privacy",
    name: "Privacy",
    component: () => import(/* webpackChunkName: "privacy" */ "../views/legal/index.vue"),
  },
  {
    path: "/FAQ",
    name: "FAQ",
    component: () => import(/* webpackChunkName: "privacy" */ "../views/About/FAQ.vue"),
  },
  {
    path: "/roxe-one",
    name: "Roxe one",
    component: () => import(/* webpackChunkName: "privacy" */ "../views/About/Roxe-One.vue"),
  },
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (to.name == "RISN") {
      if (to.hash == "#banks") {
        window.scrollTo({
          top: store.state.ui.risn[0],
          behavior: "smooth",
        });
        return;
      }
      if (to.hash == "#payment") {
        window.scrollTo({
          top: store.state.ui.risn[1],
          behavior: "smooth",
        });
        return;
      }
      if (to.hash == "#global") {
        window.scrollTo({
          top: store.state.ui.risn[2],
          behavior: "smooth",
        });
        return;
      }
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = to.name?.toString() || "";
  next();
});
/*router.beforeEach((to, from, next) => {
  console.log(process.env.ENV);
  if (store.state.account.tokenValue == '' && (to.name != 'Login' && to.name != 'components')) {
    // 获取用户信息
    const tk = localStorage.getItem('__token');
    if (tk) {
      localStorage.removeItem('__token');
      userinfo(tk).then(res => {
        store.commit('account/setAccount', {
          userId: res.userId,
          tokenValue: tk,
          account: res.phone
        });
        localStorage.setItem('__token', tk);
        next();
      }).catch(() => {
        next({ path: '/login' });
      })
    } else {
      next({ path: '/login' });
    }

    return
  }
  next();
})*/

export default router;
