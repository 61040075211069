import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import roxe from '~/roxe';
import Icon from './assets/icons/icon.vue';
import trans from './helper/trans';
import trnew from './helper/transnew';
import './main.less'
import {px2pm,resizeBus,isMobile} from '@/helper'

document.documentElement.style.fontSize = '16px'
//
if(document.documentElement.clientWidth  < 800) {
  let scale = document.documentElement.clientWidth / 375;
  scale = scale>2?2:scale;
  document.documentElement.style.fontSize = `${scale*100}%`
} else {
  let scale = document.documentElement.clientWidth / 1280;
  scale = scale>1?1:scale;
  document.documentElement.style.fontSize = `${scale*100}%`
}
let tier = 0;
window.onresize = () => {
  if(document.documentElement.clientWidth  < 800) {
    let scale = document.documentElement.clientWidth / 375;
    scale = scale>1.3?1.3:scale;
    document.documentElement.style.fontSize = `${scale*100}%`
  } else {
    let scale = document.documentElement.clientWidth / 1280;
    scale = scale>1?1:scale;
    document.documentElement.style.fontSize = `${scale*100}%`
  }
  if(tier == 0) {
    tier = window.setTimeout(() => {
      resizeBus.list().forEach((fun: any) => {
        fun();
        tier = 0;
      });
    },500);
  }
}

const app = createApp(App);
app.mixin({
  methods:{
    px2pm,
    isMobile
  }
})
app.directive('trans',trans).directive('trnew',trnew).component('RIcon',Icon).use(roxe).use(store).use(router).mount('#app')
