export const VideoList = [require('../assets/video/v01.jpg'),
  require('../assets/video/v02.jpg'),
  require('../assets/video/v03.jpg'),
  require('../assets/video/v04.jpg'),
  require('../assets/video/v05.jpg'),
  require('../assets/video/v06.jpg'),
  require('../assets/video/v07.jpg'),
  require('../assets/video/v08.jpg'),
  require('../assets/video/v09.jpg'),
  require('../assets/video/v10.jpg'),
  require('../assets/video/v11.jpg'),
  require('../assets/video/v12.jpg'),
  require('../assets/video/v13.jpg'),
  require('../assets/video/v14.jpg'),
  require('../assets/video/v15.jpg'),
  require('../assets/video/v16.jpg'),
  require('../assets/video/v17.jpg'),
  require('../assets/video/v18.jpg'),
  require('../assets/video/v19.jpg'),
  require('../assets/video/v20.jpg'),
  require('../assets/video/v21.jpg'),
  require('../assets/video/v22.jpg'),
  require('../assets/video/v23.jpg'),
  require('../assets/video/v24.jpg'),
  require('../assets/video/v25.jpg'),
  require('../assets/video/v26.jpg'),
  require('../assets/video/v27.jpg'),
  require('../assets/video/v28.jpg'),
  require('../assets/video/v29.jpg'),
  require('../assets/video/v30.jpg'),
  require('../assets/video/v31.jpg'),
  require('../assets/video/v32.jpg'),
  require('../assets/video/v33.jpg'),
  require('../assets/video/v34.jpg'),
  require('../assets/video/v35.jpg'),
  require('../assets/video/v36.jpg'),
  require('../assets/video/v37.jpg'),
  require('../assets/video/v38.jpg'),
  require('../assets/video/v39.jpg'),
  require('../assets/video/v40.jpg'),
  require('../assets/video/v41.jpg'),
  require('../assets/video/v42.jpg'),
  require('../assets/video/v43.jpg'),
  require('../assets/video/v44.jpg'),
  require('../assets/video/v45.jpg'),
  require('../assets/video/v46.jpg'),
  require('../assets/video/v47.jpg'),
  require('../assets/video/v48.jpg'),
  require('../assets/video/v49.jpg'),
  require('../assets/video/v50.jpg'),
  require('../assets/video/v51.jpg'),
  require('../assets/video/v52.jpg'),
  require('../assets/video/v53.jpg'),
  require('../assets/video/v54.jpg'),
  require('../assets/video/v55.jpg'),
  require('../assets/video/v56.jpg'),
  require('../assets/video/v57.jpg'),
  require('../assets/video/v58.jpg'),
  require('../assets/video/v50.jpg'),
  require('../assets/video/v60.jpg')
]