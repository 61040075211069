
import { defineComponent } from "vue";
import {useStore} from 'vuex';
import { RootState } from "@/store";

export default defineComponent({
  setup() {
    const store = useStore<RootState>();
    const handlesave = (save = false) => {
      store.commit('ui/setCookie', false);
      sessionStorage.setItem('_cookie','1');
      if(save) {
        localStorage.setItem('_cookie','1')
      }
    };
    return { handlesave };
  },
});
