
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();
    const gotolink = (url: string, nav = false) => {
      if (nav) {
        window.location.href = url;
        return;
      }
      router.push(url);
    };
    const calsize = (cc: number) => {
      if (document.documentElement.clientWidth < 800) {
        return cc * 1.3;
      } else {
        return cc;
      }
    };
    return { calsize, gotolink };
  },
});
