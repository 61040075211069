import { createStore } from 'vuex'
import ui, {UIState} from './modules/ui'
import account, {AccountState} from './modules/user'

export interface RootState {
  ui: UIState,
  account: AccountState
}

export default createStore<RootState>({
  modules: {
    ui,
    account
  }
})
