export interface AccountState {
  userId:string;
  tokenValue:string;
  account:string;
}

function state():AccountState {
  return {
    userId:'',
    tokenValue:'',
    account:''
  }
}

const actions = {
}
const mutations = {
  setAccount(_state:AccountState, user:{userId:string,tokenValue:string,account:string}):void {
    _state.userId = user.userId;
    _state.tokenValue = user.tokenValue;
    _state.account = user.account;
    localStorage.setItem('__token',user.tokenValue);
  }
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};