
import { defineComponent, ref } from "vue";
import Poper from "../../poper/src/index.vue";

export default defineComponent({
  name: "RSelect",
  components: { Poper },
  props: {
    title: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ["command", "on-mouseleave", "update:modelValue"],
  setup(props, { emit }) {
    const showmenu = ref(false);
    const drop = ref(null);
    const left = ref(0);
    const top = ref(0);
    const width= ref('auto');
    const handleclick = (item: any) => {
      emit("update:modelValue", item.key || item);
      emit("command", item);
      handleleave();
    };
    let timer: any = 0;
    const handleenter = () => {
      showmenu.value = true;
      const dom: any = drop.value;
      const rect: any = dom.getBoundingClientRect();
      width.value = `${rect.width}px`;
      const y = window.pageYOffset;
      const x = window.pageXOffset;
      left.value = rect.left + x;
      top.value = rect.bottom + 1 + y;
      if (timer) {
        clearTimeout(timer);
      }
      setTimeout(()=>{
        window.addEventListener('click',handleleave)
      },100)
      timer = 0;
    };
    const handleleave = () => {
      showmenu.value = false;
      window.removeEventListener('click',handleleave)
    };
    const handleopen = () => {
      if(showmenu.value) {
        handleleave()
      } else {
         handleenter();
      }
    }
    return { width,handleopen,left, top, showmenu, handleclick, drop };
  },
});
