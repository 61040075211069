import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-507d6207"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "full"
}
const _hoisted_2 = { class: "popview" }
const _hoisted_3 = {
  key: 0,
  class: "popheader"
}
const _hoisted_4 = { class: "pop-title" }
const _hoisted_5 = { class: "body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_r_icon = _resolveComponent("r-icon")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.open)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.showHeader)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1),
                    _createElementVNode("div", {
                      class: "closebtn",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeme('close')))
                    }, [
                      _createVNode(_component_r_icon, {
                        icon: "close",
                        size: 18
                      })
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}