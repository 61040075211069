
import { useRouter } from "vue-router";
import { defineComponent, ref, reactive, computed } from "vue";

export default defineComponent({
  props: ["theme"],
  setup(props) {
    const size = ref(106);
    const router = useRouter();
    const data = reactive({
      menu: [
        {
          title: "Products",
          key: "Products",
          items: [
            {
              title: "Roxe Pay",
              key: "/merchant",
            },
            {
              title: "Roxe One",
              key: "/roxe-one",
            },
            {
              title: "RISN",
              key: "/risn",
            }
          ],
        },
        {
          title: "Developers",
          key: "/developers",
        },
        {
          title: "Company",
          key: "Company",
          items: [
            {
              title: "About Us",
              key: "/about-us",
            },
            {
              title: "FAQs",
              key: "/FAQ",
            },
            {
              title: "Case Study",
              key: "/case-studies-ecsfin",
            },
            {
              title: "Careers",
              key: "/career",
            },
          ],
        },
      ],
    });
    const handlecmd = (cmd: string) => {
      if (cmd.startsWith("https://")) {
        window.location.href = cmd;
        return;
      }
      isOpen.value = false;
      mu.value = "mu";
      if (props.theme == "white") {
        mu.value = "muw";
      }
      router.push(cmd);
    };
    const gotolink = (link: string) => {
      router.push(link);
    };
    const isOpen = ref(false);
    const mu = ref("mu");
    if (props.theme == "white") {
      mu.value = "muw";
    }
    const handleopen = () => {
      if (isOpen.value) {
        isOpen.value = false;
        mu.value = "mu";
        if (props.theme == "white") {
          mu.value = "muw";
        }
      } else {
        isOpen.value = true;
        mu.value = "close";
      }
    };
    const themek = computed(() => {
      if (isOpen.value) {
        return "white";
      }
      return props.theme;
    });
    return { themek, mu, isOpen, handleopen, size, data, handlecmd, gotolink };
  },
});
