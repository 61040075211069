export interface UIState {
  mode:string;
  risn:number[];
  showjob:boolean,
  showcookie:boolean,
  pop:{
    show:boolean;
    title:string;
    desc:string;
    cb: any;
  }
}
const cok = localStorage.getItem('_cookie') || sessionStorage.getItem('_cookie');
function state():UIState {
  return {
    mode:'default',
    risn:[0,0,0],
    showjob:false,
    showcookie:!cok,
    pop:{
      show:false,
      title:'Message',
      desc:'Hellow',
      cb: null,
    }
  }
}

const actions = {

}
const mutations = {
  setRisn:(_state:UIState, risn: number[]):void => {
    _state.risn[0] = risn[0];
    _state.risn[1] = risn[1];
    _state.risn[2] = risn[2];
  },
  setMode:(_state:UIState,mode:string):void => {
    _state.mode = mode;
  },
  setJob:(_state:UIState,show: boolean):void => {
    _state.showjob = show
  },
  setCookie:(_state:UIState,show: boolean):void => {
    _state.showcookie = show
  },
  openPop:(_state:UIState,info: any) => {
    _state.pop.title = info.title;
    _state.pop.desc = info.desc;
    _state.pop.show = true;
    _state.pop.cb = info.cb;
  },
  closePop:(_state:UIState) => {
    _state.pop.show = false;
  }
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};