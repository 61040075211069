
import { defineComponent,ref } from 'vue'
import MItem from './item.vue';

export default defineComponent({
  name:"RMenu",
  components:{MItem},
  props:{
    data:{
      type:Array,
      default:()=>[]
    },
    theme:{
      type:String,
      default:""
    },
    mode:{
      type:String,
      default:"horizontal"
    }
  },
  setup(props,{emit}) {
    const handlecommand = (key:string) => {
      emit("command",key);
    }
    const childs = ref(null);
    const opensub = () => {
       console.log(childs.value)
    }

    return {childs,opensub,handlecommand}
  }
})
