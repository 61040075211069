import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["readonly", "autocomplete", "value", "placeholder", "rows"]
const _hoisted_2 = ["readonly", "type", "autocomplete", "value", "placeholder"]
const _hoisted_3 = {
  key: 2,
  class: "error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['r-input',_ctx.errs?'error':''])
  }, [
    (_ctx.type == 'textarea')
      ? (_openBlock(), _createElementBlock("textarea", {
          key: 0,
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleinput && _ctx.handleinput(...args))),
          onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handlechange && _ctx.handlechange(...args))),
          onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleblur && _ctx.handleblur(...args))),
          onFocus: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handlefocus && _ctx.handlefocus(...args))),
          readonly: _ctx.readonly,
          autocomplete: _ctx.autocomplete,
          value: _ctx.value || _ctx.modelValue,
          placeholder: _ctx.placeholder,
          rows: _ctx.rows
        }, null, 40, _hoisted_1))
      : (_openBlock(), _createElementBlock("input", {
          key: 1,
          readonly: _ctx.readonly,
          type: _ctx.type,
          autocomplete: _ctx.autocomplete,
          value: _ctx.value || _ctx.modelValue,
          placeholder: _ctx.placeholder,
          onInput: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleinput && _ctx.handleinput(...args))),
          onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handlechange && _ctx.handlechange(...args))),
          onBlur: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleblur && _ctx.handleblur(...args))),
          onFocus: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.handlefocus && _ctx.handlefocus(...args)))
        }, null, 40, _hoisted_2)),
    (_ctx.errs)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.errs), 1))
      : _createCommentVNode("", true)
  ], 2))
}