import type { App } from 'vue'
import Button  from "../button";
import Input from '../input';
import Panel from '../panel';
import Page from '../page';
import DropDown from '../dropdown';
import Menu from '../menu';
import Select from '../select';
import Checkbox  from '../checkbox';
import '../theme-chalk'

const components = [
  Input,
  Checkbox,
  Button,
  Panel,
  Page,
  DropDown,
  Select,
  Menu
];

const install = (app: App) :void => {
  components.forEach(component => {
    app.component(component.name, component)
  })
}

export {
  Input,
  Checkbox,
  Button,
  Panel,
  Page,
  DropDown,
  Select,
  Menu
}

export default {
  install,
}