
import { defineComponent, computed } from 'vue'
const req = (require as any).context('./svg', false, /\.svg$/);
const requireAll = (requireContext: any) => requireContext.keys().map(requireContext);
requireAll(req);
export default defineComponent({
  props:{
    size:{
      type:[Number,String],
      default:32
    },
    width:{
      type:[Number,String],
      default:0
    },
    height:{
      type:[Number,String],
      default:0
    },
    icon:{
      type:String,
      default:""
    },
    className:{
      type:String,
      default:""
    }
  },
  setup(props) {
    const iconName = computed(()=>{
      return `#icon-${props.icon}`
    });
    const classNames = computed(()=>{
      return props.className
    });
    const w = computed(()=>{
      if(props.width) {
        if(props.width.toString().endsWith('%') || props.width.toString().endsWith('px')) {
          return props.width;
        } else {
          return `${props.width}px`
        }
      } else {
        if(props.height) {
          return 'auto'
        } else {
          return `${props.size}px`
        }
      }
    });
    const h = computed(()=>{
      if(props.height) {
        if(props.height.toString().endsWith('%') || props.height.toString().endsWith('px')) {
          return props.height;
        } else {
          return `${props.height}px`
        }
      } else {
        if(props.width) {
          return 'auto'
        } else {
          return `${props.size}px`
        }
      }
    });
    return {
      h,
      w,
      iconName,
      classNames
    }
  }
})
