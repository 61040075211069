import { renderSlot as _renderSlot, vShow as _vShow, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _withDirectives(_createElementVNode("div", {
      style: _normalizeStyle({left:`${_ctx.left}px`,top:`${_ctx.top}px`}),
      class: "r-poper"
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 4), [
      [_vShow, !_ctx.hide]
    ])
  ]))
}