
import { defineComponent,onUpdated } from 'vue'

export default defineComponent({
  props:['open','title','showHeader'],
  setup(props,{emit}) {
    const closeme = () => {
      emit('close');
    }
    if(props.open) {
      document.body.style.overflow = "hidden";
    }
    onUpdated(() => {
      if (props.open) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    });
    return {closeme}
  },
})
