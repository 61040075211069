
import { computed, defineComponent, onMounted } from "vue";
import Pop from "@/components/model/pop.vue";
import Tail from "@/components/tail/index.vue";
import { useStore } from "vuex";
import { RootState } from "@/store";
import Cookie from '@/components/cook/index.vue';

export default defineComponent({
  components: { Pop, Tail,Cookie },
  setup() {
    const store = useStore<RootState>();
    let dom: any = null;
    onMounted(() => {
      dom = document.createElement("script");
      dom.src = "https://boards.greenhouse.io/embed/job_board/js?for=roxe";
      document.body.appendChild(dom);
    });
    const showjob = computed(() => {
      return store.state.ui.showjob;
    });
    const showcookie = computed(()=>{
      return store.state.ui.showcookie;
    })
    return { showjob,showcookie };
  },
});
