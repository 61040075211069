import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MItem = _resolveComponent("MItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['r-menu',`r-menu-${_ctx.mode}`,_ctx.theme?`r-menu-${_ctx.theme}`:''])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item) => {
      return (_openBlock(), _createBlock(_component_MItem, {
        f: "childs",
        onSubopen: _ctx.opensub,
        theme: _ctx.theme,
        onCommand: _ctx.handlecommand,
        data: item,
        key: item.key || item,
        mode: _ctx.mode
      }, null, 8, ["onSubopen", "theme", "onCommand", "data", "mode"]))
    }), 128))
  ], 2))
}