import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../assets/logoh.png'
import _imports_1 from '../../assets/logow.png'


const _withScopeId = n => (_pushScopeId("data-v-398e6bdf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { href: "/" }
const _hoisted_2 = {
  key: 0,
  class: "logo",
  src: _imports_0
}
const _hoisted_3 = {
  key: 1,
  class: "logo",
  src: _imports_1
}
const _hoisted_4 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_r_menu = _resolveComponent("r-menu")!
  const _component_r_button = _resolveComponent("r-button")!
  const _component_r_icon = _resolveComponent("r-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(['header', _ctx.themek ? `header-${_ctx.themek}` : ''])
    }, [
      _createElementVNode("a", _hoisted_1, [
        (_ctx.themek == 'white')
          ? (_openBlock(), _createElementBlock("img", _hoisted_2))
          : (_openBlock(), _createElementBlock("img", _hoisted_3))
      ]),
      _createVNode(_component_r_menu, {
        id: "font-size",
        class: "hide",
        onCommand: _ctx.handlecmd,
        theme: _ctx.theme || 'dark',
        data: _ctx.data.menu
      }, null, 8, ["onCommand", "theme", "data"]),
      _createVNode(_component_r_button, {
        id: "font-size",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.gotolink('/contact'))),
        class: "hide btn20",
        type: _ctx.theme == 'white' ? 'blue' : 'second'
      }, {
        default: _withCtx(() => [
          _createTextVNode("Contact Us")
        ]),
        _: 1
      }, 8, ["type"]),
      _createVNode(_component_r_icon, {
        onClick: _ctx.handleopen,
        class: "show",
        icon: _ctx.mu,
        size: 24
      }, null, 8, ["onClick", "icon"])
    ], 2),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      _createVNode(_component_r_menu, {
        onCommand: _ctx.handlecmd,
        theme: "white",
        mode: "vertical",
        data: _ctx.data.menu
      }, null, 8, ["onCommand", "data"]),
      _createVNode(_component_r_button, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.gotolink('/contact'))),
        class: "btn20",
        type: 'blue'
      }, {
        default: _withCtx(() => [
          _createTextVNode("Contact Us")
        ]),
        _: 1
      })
    ], 512), [
      [_vShow, _ctx.isOpen]
    ])
  ], 64))
}