interface BinInfo {
  value: any;
}
export default {
  mounted(el: HTMLElement, binding:BinInfo ){
    //app ping
    if(document.documentElement.clientWidth <= 8000) {
      return;
    }
    const args = binding.value || {dir:'bottom',val:200,out:0};
    const out = args.out || args;
    const val = args.val || 200;
    const dir = args.dir || 'bottom';
    const btn = el.getBoundingClientRect().top + el.clientHeight + window.scrollY;
    el.style.opacity ='0';
    el.style.transform = `translate(0px,300px)`
    const checkin = () => {
      const  visibleTop = window.scrollY;
      const visiblebottom = window.screenY + document.documentElement.clientHeight;
      if(btn < visibleTop + visiblebottom && btn > visibleTop) {
        return true;
      }
      return false;
    }
    const onscroll =  () => {
      if(checkin()) {
        window.removeEventListener('scroll',onscroll);
        setTimeout(() => {
          el.className = el.className + ' enter'
          el.style.opacity ='1';
          el.style.transform = 'translate(0px, 0px)'
        }, out);
      }
    }
    if(checkin()) {
      setTimeout(() => {
        el.className = el.className + ' enter'
        el.style.opacity ='1';
        el.style.transform = 'translate(0px, 0px)'
      }, out);
    } else {
      // 监听滚动事件
     window.addEventListener('scroll',onscroll);
    }
  }
}