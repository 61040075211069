import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e400506"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "desc ft18 black" }
const _hoisted_2 = { class: "btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_r_button = _resolveComponent("r-button")!
  const _component_Model = _resolveComponent("Model")!

  return (_openBlock(), _createBlock(_component_Model, {
    showHeader: true,
    title: _ctx.data.title,
    open: _ctx.data.show,
    onClose: _ctx.handleclose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.data.desc), 1),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_r_button, { onClick: _ctx.handleclose }, {
          default: _withCtx(() => [
            _createTextVNode("OK")
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 8, ["title", "open", "onClose"]))
}