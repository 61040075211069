
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
  reactive,
  nextTick,
} from "vue";
import Header from "@/components/header/index.vue";
import Tail from "@/components/tail/index.vue";
import Slide from "@/components/slide/index.vue";
import { useRouter } from "vue-router";
import { isMobile, resizeBus } from "@/helper";
import { VideoList } from "@/helper/video";

const cbbc = require("../assets/imgs/cbdc.png");
const mining = require("../assets/imgs/mining.png");
const app = require("../assets/imgs/app.png");
const first = require("../assets/video/v01.jpg");

export default defineComponent({
  name: "Home",
  components: { Header, Slide, Tail },
  setup() {
    const bkcolor = ref("");
    const idx = ref(0);
    const vIndex = ref(0);
    const handchange = (num: number) => {
      idx.value = num;
    };
    // 预加载图片
    const start = false;
    const data = reactive({
      app,
      cbbc,
      mining,
      tops: [0, 0, 0],
    });
    const router = useRouter();
    const flxx = ref(null);
    const winScroll = () => {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 80) {
        bkcolor.value = "dark";
      } else {
        bkcolor.value = "";
      }

    };
    let tm1 = 0;
    let tm2 = 0;
    const imgList = [];
    if (isMobile()) {
      for (let i = 0; i < VideoList.length; i++) {
        let img = new Image();
        img.src = VideoList[i];
        img.onload = () => {
          imgList.push(img);
          if (imgList.length == VideoList.length) {
            tm2 = window.setInterval(() => {
              video.value = VideoList[vIndex.value];
              vIndex.value++;
              if (vIndex.value >= VideoList.length) {
                vIndex.value = 0;
              }
            }, 100);
          }
        };
      }
    }
    onMounted(() => {
      setTimeout(() => {
        window.addEventListener("scroll", winScroll);
        let dom: any = domapp.value;
        data.tops[0] = dom.getBoundingClientRect().top + window.scrollY;
        dom = domcbdc.value;
        data.tops[1] = dom.getBoundingClientRect().top + window.scrollY;
        dom = dommining.value;
        data.tops[2] = dom.getBoundingClientRect().top + window.scrollY;
      });
      const dom1: any = flxx.value;
      resizeBus.add("home", () => {
        clientHeight.value = document.documentElement.clientHeight;
      });
      tm1 = window.setInterval(() => {
        dom1.style.transform = `translate(-${parts.value}px,0px)`;
        parts.value += 2;
        if (parts.value >= 2310) {
          parts.value = 185;
        }
      }, 30);
    });
    onBeforeUnmount(() => {
      resizeBus.remove("home");
      imgList.length = 0;
      window.removeEventListener("scroll", winScroll);
      clearInterval(tm1);
      clearInterval(tm2);
    });
    const showapp = ref(true);
    const showcbdc = ref(false);
    const showmin = ref(false);
    const showimg = computed(() => {
      return showapp.value || showcbdc.value || showmin.value;
    });
    const gotolink = (url: string, outsite = false) => {
      if (outsite) {
        window.location.href = url;
        return;
      }
      router.push(url);
    };
    const domapp = ref(null);
    const domcbdc = ref(null);
    const dommining = ref(null);
    const parts = ref(100);
    const slide = ref(null);
    const gotoside = (index = 0) => {
      idx.value = index;
      if (slide.value) {
        (slide.value as any).gotoside(index);
      }
    };
    const clientHeight = ref(0);
    clientHeight.value = document.documentElement.clientHeight;
    const divt = computed(() => {
      if (clientHeight.value > 680) {
        return (clientHeight.value - 680) / 2;
      }
      return 0;
    });
    const video = ref(first);

    return {
      video,
      divt,
      slide,
      gotoside,
      flxx,
      parts,
      domapp,
      domcbdc,
      dommining,
      handchange,
      idx,
      gotolink,
      data,
      showimg,
      bkcolor,
      showapp,
      showcbdc,
      showmin,
    };
  },
});
